#root{
  background-color: #f9f9f9;
}

a{
  color: #000000;
}

header{
  padding: 15px;
  position: fixed;
  line-height: 18px;
  background-color: #f9f9f9;
  width: calc(100% - 30px);
}

footer{
  margin-top: 70px;
  margin-bottom: 20px;
  font-size: 12px;
}

table {
  border-collapse: collapse;
  font-size: 0.9em;
  overflow-x: scroll;
  width: 100%;
}

table, th, td{
  border: 1px solid #cccccc;
}

td{
  padding: 15px;
  vertical-align: top;
}

.hello, .section h2{
  margin-top: 0;
}
.underline{
  text-decoration: underline;
}

.job{
  white-space: nowrap;
}

.activeJob{
  background-color: #f3f2ef;
}

.jobDescription{
  min-width: 300px;
}

.jobTitle{
  font-weight: bold;
  font-size: 1.1em;
}

.section{
  padding: 150px 15px 0px 15px;
}

.aboutSection ul {
  list-style-type: none;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.aboutSection li{
  font-size: 0.9em;
  margin-bottom: 18px;
}

.aboutSection li:before {
  content: "• ";
}

.projectSection li, .contactSection li, .jobDescriptionText li {
  margin-top: 18px;
  font-size: 0.9em;
}

.jobDescriptionText p {
  white-space: pre;
  font-size: 0.9em;
  text-wrap: auto;
}

.overflow{
  overflow-x: scroll;
}

.verySmall{
  font-size: 0.7em;
  margin-top: -10px;
  margin-bottom: 10px;
}

@media (min-width: 800px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  #main{
    width: 60%;
    margin: 0 auto;
  }
}